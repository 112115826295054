<template>

  <div class="hero wf-section">
    <!-- <div>
      <header-nav></header-nav>
    </div> -->
    <div class="hero__container w-container">
      <h1>Discover electronic music artists and events</h1>

      <div class="hero__search__container">
        <input
          v-model="search"
          type="text"
          class="hero__search__input"
          autofocus="true"
          maxlength="256"
          name="search"
          data-name="search"
          placeholder="Search by artist, genre, or tag"
          id="search-2"
        >
      </div>
      <div class="hero__filters">
        <select
          id="genres"
          name="genres"
          data-name="genres"
          v-model='subgenre'
          :class="subgenre ? 'selected': ''"
        >
          <option value="">All genres</option>
          <!-- <option value="Abstract">Abstract</option> -->
          <!-- <option value="Acid">Acid</option> -->
          <option
            style="color:unset"
            value="Afro House"
          >Afro house</option>
          <option value="Deep house">Deep house</option>
          <option value="Downtempo">Downtempo</option>
          <option value="Drum &amp; Bass">Drum &amp; Bass</option>
          <option value="Dubstep">Dubstep</option>
          <option value="Electronica">Electronica</option>
          <option value="House">House</option>
          <option value="Indie Dance">Indie dance</option>
          <option value="Melodic house">Melodic house</option>
          <option value="Melodic techno">Melodic techno</option>
          <option value="Minimal techno">Minimal techno</option>
          <option value="Minimal house">Minimal house</option>
          <option value="Organic house">Organic house</option>
          <option value="Progressive house">Progressive house</option>
          <option value="Psytrance">Psytrance</option>
          <option value="Tech House">Tech house</option>
          <option value="Techno">Techno</option>
        </select>

        <select
          v-model="city"
          id="locations"
          name="locations"
          data-name="locations"
          :class="city ? 'selected': ''"
        >
          <option value="">All locations</option>
          <option value="Copenhagen">Copenhagen</option>
          <option value="Aalborg">Aalborg</option>
          <option value="Århus">Århus</option>
          <option value="Berlin">Berlin</option>
          <option value="Horsens">Horsens</option>
          <option value="Odense">Odense</option>
          <option value="Køge">Køge</option>
          <option value="Kolding">Kolding</option>
          <option value="Sønderborg">Sønderborg</option>
          <option value="Svendborg">Svendborg</option>
        </select>
        <select
          v-model="gender"
          id="genders"
          name="genders"
          data-name="genders"
          :class="gender ? 'selected': ''"
        >
          <option value="">All genders</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Other">Other</option>
          <option value="Unspecified">Unspecified</option>
        </select>
        <select
          v-model="type"
          id="type"
          name="type"
          data-name="type"
          :class="type ? 'selected': ''"
        >
          <option value="">All types</option>
          <option value="DJ">DJ</option>
          <option value="Producer">Producer</option>
          <option value="DJ &amp; Producer">DJ &amp; Producer</option>
        </select>
      </div>

    </div>
  </div>

  <div class="content wf-section">
    <div class="container">
      <div class="flex--wrap">
        <div
          v-for="artist in filterArtists"
          class="feed__item"
          :key="artist._id"
        >

          <a
            @click="$root.loading=true"
            :href="'/'+artist.slug"
            class="feed__image"
          ><img
              :src="artist.avatar"
              loading="lazy"
              alt=""
            ></a>
          <div class="feed__description">
            <div class="feed__title mb6">{{artist.artistname}}</div>
            <div class="feed__text--small">{{artist.type}}<span v-if="artist.type && artist.city"> | </span> {{artist.city}}</div>

            <div
              v-if="artist.subgenres.length"
              role="list"
              class="tag__list"
            >
              <div
                v-for="subgenre in artist.subgenres"
                role="listitem"
                class="tag__item"
                :key="subgenre._id"
              >
                <div
                  fs-cmsfilter-field="Subgenres"
                  class="tag"
                >{{subgenre.name}}</div>
              </div>
            </div>
            <div class="icons flex--horiz--middle">
              <a
                v-if="artist.soundcloud"
                :href="artist.soundcloud"
                class="icon mr10"
              ><img src="../assets/icons/icon_soundcloud.svg" /></a>
              <a
                v-if="artist.spotify"
                :href="artist.spotify"
                class="icon mr10"
              ><img src="../assets/icons/icon_spotify.svg" /></a>
              <a
                v-if="artist.facebook"
                :href="artist.facebook"
                class="icon mr10"
              ><img src="../assets/icons/icon_facebook.svg" /></a>
              <a
                v-if="artist.instagram"
                :href="artist.instagram"
                class="icon mr10"
              ><img src="../assets/icons/icon_instagram.svg" /></a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  async beforeCreate() {},
  data: () => ({
    selected: "",
    search: "",
    artistname: "",
    city: "",
    gender: "",
    subgenre: "",
    type: "",
    artists: [],
    // mounted: ''
  }),
  methods: {
    checkSubgenres: function (artist, term) {
      let term_lc = term.toLowerCase();
      let subgenres = artist.subgenres.map((s) => s.name);
      if (!subgenres.length) {
        return false;
      }

      let match = false;
      subgenres.forEach((item) => {
        let item_lc = item.toLowerCase();
        // exact match
        if (item_lc === term_lc) {
          match = true;
        }
        // partial match
        if (item_lc.indexOf(term_lc) + 1) {
          match = true;
        }
      });
      return match;
    },

    checkSearch: function (artist) {
      let name = artist.artistname.toLowerCase();
      let term = this.search.toLowerCase().trim();
      let matching_name = name.indexOf(term) + 1;
      let matching_subgenre = this.checkSubgenres(artist, this.search);

      if (matching_name || matching_subgenre) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    filterArtists: function () {
      return this.artists.filter((artist) => {
        if (artist.filter && this.$root.visitorCountry === "Canada") {
          return false;
        }
        if (!artist.active) {
          return false;
        }
        if (this.city.length) {
          if (!artist.city || artist.city !== this.city) {
            return false;
          }
        }
        if (this.gender.length) {
          if (!artist.gender || artist.gender !== this.gender) {
            return false;
          }
        }

        if (this.type.length) {
          if (!artist.type || artist.type.indexOf(this.type) < 0) {
            return false;
          }
        }
        if (this.subgenre.length) {
          if (!this.checkSubgenres(artist, this.subgenre)) {
            return false;
          }
        }
        if (this.search.length) {
          if (!this.checkSearch(artist)) {
            return false;
          }
        }

        return true;
      });
    },
  },
  async mounted() {
    try {
      let maximum = 300;
      let minimum = 0;
      let randomnumber =
        Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
      let response1 = await fetch(
        `${this.$root.apiURL}/artists/get-artists?limit=10&skip=${randomnumber}`
      );

      let list1 = await response1.json();
      this.artists = list1;

      setTimeout(async () => {
        let response2 = await fetch(
          `${this.$root.apiURL}/artists/get-artists?limit=${randomnumber}`
        );
        let list2 = await response2.json();
        let response3 = await fetch(
          `${this.$root.apiURL}/artists/get-artists?skip=${randomnumber + 10}`
        );
        let list3 = await response3.json();
        this.artists = list1.concat(list2, list3);
        this.$gtag.event(`Feed page`, {
          method: "Google",
        });
      }),
        500;
    } catch (e) {
      console.error(e);
    }
  },
};
</script>
<style scoped>
.icons {
  margin-top: 15px;
}
.icons .icon {
  height: 20px;
  width: 20px;
}
</style>