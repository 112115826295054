<template>

  <div class="header__component">
    <div class="header__wrapper">

      <!-- LOGO -->
      <a
        @click="$root.loading=true"
        href="/"
        class="logo"
      ><img
          src="../assets/soundxpo_logo.svg"
          alt=""
          class="logo__image"
        >
        <div class="logo__beta_text">Beta</div>
      </a>

      <!-- LOGIN MENU -->
      <aside :class="loginOpen ? 'slideout__container open' : 'slideout__container'">

        <div
          style="z-index:999"
          :class="loginOpen ? 'slideout open' : 'slideout'"
        >
          <!-- <div
            @click="toggleLogin"
            class="close clickable"
          ></div> -->

          <div class="content">

            <div class="logo"><img
                height="40"
                src="../assets/soundxpo_logo.svg"
              /></div>

            <!-- LOGIN -->

            <form
              v-show="!signupOpen && loginOpen"
              data-ms-form="login"
            >
              <h4>Sign in to your account</h4>
              <section>
                <input
                  required="true"
                  data-ms-member="email"
                  placeholder="email"
                  @input="checkLoginForm()"
                  type="email"
                  v-model.trim="email"
                />
              </section>
              <section>
                <input
                  required="true"
                  data-ms-member="password"
                  placeholder="password"
                  @input="checkLoginForm()"
                  type="password"
                  v-model.trim="password"
                />
                <a
                  href="#/ms/password-reset"
                  class="input__helptext right clickable"
                >forgot?</a>
              </section>

              <button
                type="submit"
                class="btn--accent mt20 mb40"
                @click="login()"
              >SIGN IN</button>

              <div>New to Soundxpo? <span
                  @click="toggleSignup"
                  class="accent clickable"
                ><strong>Sign up →</strong></span></div>

            </form>

            <!-- END LOGIN -->

            <!-- SIGNUP -->

            <form
              v-show="signupOpen"
              data-ms-form="signup"
            >
              <!-- <h4>Create your account</h4> -->
              <div class="banner mb30"><strong>Note: </strong>While in beta, accounts are by invite only. To request access, <router-link
                  @click="loginOpen=false"
                  to="/contact"
                >contact us</router-link>
              </div>
              <section>
                <input
                  required="true"
                  pattern="5409"
                  placeholder="beta access code"
                  type="text"
                  v-model.trim="code"
                />
              </section>
              <section>
                <input
                  required="true"
                  minlength="3"
                  data-ms-member="name"
                  placeholder="name"
                  type="text"
                  v-model.trim="name"
                />
              </section>
              <section>
                <input
                  required="true"
                  data-ms-member="email"
                  placeholder="email"
                  type="email"
                  v-model.trim="email"
                />
              </section>
              <section>
                <input
                  minlength="8"
                  required="true"
                  data-ms-member="password"
                  placeholder="password (min 8 chars)"
                  type="password"
                  v-model.trim="password"
                />
              </section>

              <section>
                <div
                  class="ms-gdpr-opt-in checkbox mt20"
                  style=""
                ><input
                    type="checkbox"
                    data-ms-member="gdpr-opt-in"
                    id="gdpr-opt-in"
                    name="gdpr-opt-in"
                  > <label for="gdpr-opt-in">Please update me on news & events.</label>
                </div>
              </section>

              <div
                class="errors mt30"
                v-if="errors.length"
              >
                <b>Please correct the following error(s):</b>
                <ul>
                  <li
                    v-for="error in errors"
                    :key="error.index"
                  >{{ error }}</li>
                </ul>
              </div>
              <button
                :disabled="!validated"
                type="submit_"
                class="btn--accent mt20 mb40"
                @click="signup()"
              >CREATE ACCOUNT</button>

              <div>Got an account? <span
                  @click="toggleSignup"
                  class="accent clickable"
                ><strong>Login →</strong></span></div>

            </form>

          </div>
        </div>
        <div
          class="underlay"
          style="z-index:0"
          @click="toggleLogin"
        ></div>
      </aside>
      <!-- end login -->

      <!-- DESKTOP NAV-->
      <!-- menu -->
      <div class="nav__container desktop">
        <div class="nav">
          <router-link
            @click="toggleMenu"
            to="/"
            class="nav__item"
          >ARTISTS</router-link>
          <router-link
            @click="toggleMenu"
            to="/events"
            class="nav__item"
          >EVENTS</router-link>
          <router-link
            @click="toggleMenu"
            to="/contact"
            class="nav__item"
          >CONTACT</router-link>
          <span
            v-if="$root.member.loggedIn"
            @click="toggleDropdown"
            class="icon__account clickable"
          ><img src="../assets/icons/icon_account.svg" /></span>
          <button
            type="button"
            v-if="!$root.member.loggedIn"
            @click="switchToLogin"
            class="btn--accent ml40"
          >SIGN IN</button>
        </div>
      </div>
      <!-- account dropdown -->
      <aside
        class="account_menu"
        v-show="dropDownOpen"
        ref="dropDown"
        id="dropDown"
        @focusout="toggleDropdown"
        tabindex="0"
      >
        <router-link to="/account">My account</router-link>
        <span
          class="clickable"
          @click="logout()"
        >Logout</span>
      </aside>
      <!-- end desktop nav -->

      <!-- MOBILE NAV -->
      <!-- icon -->
      <div
        :class="menuOpen || loginOpen ? 'nav__menu_icon mobile open' : 'nav__menu_icon mobile'"
        @click="toggleMenu"
      >
        <i></i>
        <i></i>
        <i></i>
      </div>
      <!-- menu -->
      <aside :class="menuOpen ? 'slideout__container open mobile' : 'slideout__container mobile'">
        <nav :class="menuOpen ? 'nav open' : 'nav'">
          <router-link
            @click="toggleMenu"
            to="/"
            class="nav__item"
          >ARTISTS</router-link>
          <router-link
            @click="toggleMenu"
            to="/events"
            class="nav__item"
          >EVENTS</router-link>
          <router-link
            @click="toggleMenu"
            to="/contact"
            class="nav__item"
          >CONTACT</router-link>
          <router-link
            v-show="$root.member.loggedIn"
            @click="toggleMenu"
            to="/account"
            class="nav__item mobile"
          >MY ACCOUNT</router-link>
          <span
            v-show="$root.member.loggedIn"
            @click="toggleMenu; logout()"
            class="nav__item btn__logout mobile clickable"
          >LOGOUT</span>
          <button
            type="button"
            v-if="!$root.member.loggedIn"
            @click="switchToLogin"
            class="btn--accent ml40"
          >SIGN IN</button>
        </nav>

        <!-- END -->
        <div
          class="underlay"
          style="z-index:0"
          @click="toggleMenu"
        ></div>
      </aside>
      <!-- end mobile nav -->

    </div>
  </div>
</template>

<script>
export default {
  async beforeCreate() {},
  data: () => ({
    loggedIn: "",
    dropDownOpen: false,
    menuOpen: false,
    loginOpen: false,
    signupOpen: false,
    name: "",
    email: "",
    password: "",
    code: "",
    errors: {},
    validated: true,
  }),
  methods: {
    toggleLogin() {
      this.loginOpen = !this.loginOpen;
      this.signupOpen = false;
    },
    toggleSignup() {
      this.signupOpen = !this.signupOpen;
    },
    toggleMenu() {
      if (this.menuOpen || this.loginOpen || this.signupOpen) {
        this.menuOpen = false;
        this.loginOpen = false;
        this.signupOpen = false;
      } else {
        this.menuOpen = true;
        this.loginOpen = false;
        this.signupOpen = false;
      }
    },
    switchToLogin() {
      this.menuOpen = false;
      this.loginOpen = true;
    },
    toggleDropdown() {
      if (!this.dropDownOpen) {
        this.dropDownOpen = true;
        this.$nextTick(() => this.$refs.dropDown.focus());
      } else {
        setTimeout(() => {
          this.dropDownOpen = false;
        }, 100);
      }
    },
    checkSignupForm() {
      console.log(this.email);
      let passwordOk = this.password.length > 7 ? true : false;
      let codeOk = this.code.trim() === "5409" ? true : false;
      let emailOk = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,30})+$/.test(
        this.email
      );
      if (
        !this.name ||
        !this.email ||
        !this.password ||
        !emailOk ||
        !codeOk ||
        !passwordOk
      ) {
        return (this.validated = false);
      } else this.validated = true;
    },
    checkLoginForm() {
      console.log(this.email);
      let passwordOk = this.password.length > 7 ? true : false;
      let emailOk = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,30})+$/.test(
        this.email
      );
      if (!this.email || !this.password || !emailOk || !passwordOk) {
        return (this.validated = false);
      } else this.validated = true;
    },

    // checkForm: async function () {
    //   this.errors = [];
    //   if (!this.profileObj.slug) {
    //     this.errors.push("Username required.");
    //   } else if (this.profileObj.slug.length < 3) {
    //     this.errors.push("Username too short.");
    //   }

    //   if (this.profileObj.slug && this.profileObj.slug !== this.original_slug) {
    //     let slugOk = await this.uniqueSlug();
    //     if (!slugOk) this.errors.push("Username taken");
    //   }
    //   if (!this.profileObj.artistname) this.errors.push("Artist name required.");
    //   return this.errors.length ? false : true;
    // },

    login() {
      this.menuOpen = false;
      sessionStorage.setItem("redirect", window.location.href);
    },
    signup() {
      this.menuOpen = false;
      sessionStorage.setItem("redirect", window.location.href);
    },
    logout() {
      window.MemberStack.logout();
      this.$router.go();
    },
  },
  computed: {},
  mounted() {
    // window.MemberStack.onReady.then(member => {
    //   // store member data wherever you find appropreate
    //   this.$root.member = member;
    // });

    this.loggedIn = this.$root.member.loggedIn;
  },
};
</script>

<style scoped lang="scss">
.slideout__container {
  background-color: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 100%;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  z-index: 990;
  transition: background-color 0.4s ease;
  transition: left 0s 0.4s ease;
}
.slideout__container.open {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  transition: left 0s 0s ease;
}
.slideout {
  position: relative;
  height: 100vh;
  max-width: 80vw;
  width: 500px;
  background: #111;
  position: absolute;
  top: 0;
  right: 0;
  padding: 80px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transform: translatex(100%);
  transition: transform 0.2s ease-in-out;
  overflow: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.slideout::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.slideout .content {
  width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.slideout__container .underlay {
  width: 100%;
  height: 100%;
}
.slideout .logo {
  margin-bottom: 30px;
}
.slideout h4 {
  font-weight: 500;
  margin-bottom: 30px;
}
.slideout.open {
  transform: translatex(0);
}

.slideout,
.slideout h4 {
  color: #ccc;
}
.slideout form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 260px;
  align-items: center;
}
.slideout form section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 15px 0;
}
.slideout form input {
  background: #010101 !important;
  color: #ccc !important;
  border: 0;
  border-radius: 0;
  border-left: 2px solid transparent;
  transition: border 300ms ease;
}

.slideout form input:-webkit-autofill,
.slideout form input:-webkit-autofill:hover,
.slideout form input:-webkit-autofill:focus,
.slideout form input:-webkit-autofill:active {
  background-color: inherit !important;
  box-shadow: 0 0 0 30px #010101 inset !important;
  -webkit-box-shadow: 0 0 0 30px #010101 inset !important;
  -webkit-text-fill-color: #ccc !important;
  border: none;
}
.slideout form input:-webkit-autofill:focus {
  border-left: 2px solid #ffe100;
}
.slideout form input:focus {
  outline: none;
  border-left: 2px solid #ffe100;
}
.slideout form input:valid {
  outline: none;
  border-left: 2px solid #51c3a0;
}

.slideout .checkbox {
  display: flex;
  align-items: center;
}
.slideout .checkbox label {
  margin: 0 0 0 10px;
  font-size: 0.8em;
  font-weight: 300;
  line-height: 130%;
}
.slideout .input__helptext.right {
  font-weight: 100;
  margin-top: 6px;
  align-self: flex-end;
  color: #ffe100;
}
.slideout .logo {
  opacity: 0.8;
  margin: 0 0 40px 0;
}
.slideout .close {
  position: fixed;
  top: 35px;
  right: 30px;
  width: 20px;
  height: 20px;
  opacity: 0.5;
  background-image: url("../assets/icons/icon_close.svg");
}
.slideout button {
  align-self: center;
  padding: 8px 20px;
  border-radius: 50px;
}
.slideout button:disabled {
  opacity: 0.3;
}
.slideout .banner {
  font-size: 0.85em;
  line-height: 130%;
  background-color: rgba(255, 225, 0, 0.1);
  border: 1px #ffe100 solid;
  padding: 10px;
  border-radius: 10px;
}
.slideout .banner a {
  text-decoration: underline;
  color: inherit;
}
.account_menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 80px;
  right: 30px;
  padding: 12px 20px;
  background: #222;
  border-radius: 6px;
  outline: none;
  border: none;
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.15);
  z-index: 990;
}
.account_menu a,
.account_menu span {
  margin: 6px 0;
  min-height: 16px;
  color: #999;
}
.account_menu a:hover,
.account_menu span:hover {
  color: #fff;
}
.icon__account img {
  margin-left: 40px;
  height: 30px;
  width: 30px;
  opacity: 0.8;
}
.header__component {
  display: flex;
  width: 100%;
  margin-right: 0px;
  margin-left: 0px;
  justify-content: center;
  align-items: center;
}
.header__wrapper {
  position: relative;
  padding: 0 30px;
  display: flex;
  width: 100%;
  height: 90px;
  max-width: 1170px;
  margin-right: 0px;
  margin-left: 0px;
  justify-content: space-between;
  align-items: center;
}
.nav__container {
  display: block;
}
.nav {
  display: flex;
  align-items: center;
}
.nav__item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  color: hsla(0, 0%, 100%, 0.7);
  padding: 12px 20px;
  text-align: left;
  margin: 0 10px;
}
.nav__item.active {
  border-bottom: 2px solid #ffe100;
  color: #fff;
}
.nav__item:hover {
  border-bottom: 2px solid #333;
  color: #fff;
}

.nav__item.router-link-active {
  border-bottom: 2px solid #ffe100;
  color: #fff;
}

@media screen and (max-width: 767px) {
  // @media screen and (max-width: 900px) {
  .slideout form section {
    margin: 6px 0;
  }
  .slideout form input {
    margin: 0;
  }
  .nav__menu_icon {
    display: flex;
    height: 18px;
    width: 24px;
    margin: 0;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
    user-select: none;
    z-index: 9999;

    & i {
      height: 2px;
      background: #ccc;
      border-radius: 2px;
      transition: all 0.3s ease;
    }
    & i:nth-child(1) {
      margin-left: auto;
      width: 16px;
    }
    & i:nth-child(2) {
      width: 24px;
    }
    & i:nth-child(3) {
      width: 16px;
    }
  }
  .nav__menu_icon.open {
    position: fixed;
    top: 35px;
    right: 30px;
    & i:nth-child(1) {
      transform: translate(-1px, 5px) rotate(-45deg);
    }
    & i:nth-child(2) {
      transform: rotate(45deg);
      width: 24px;
    }
    & i:nth-child(3) {
      transform: translate(1px, -5px) rotate(-45deg);
    }
  }
  .nav {
    height: 100vh;
    width: 80vw;
    background: #010101;
    position: absolute;
    top: 0;
    right: 0;
    padding: 80px 60px 0 60px;
    display: flex;
    flex-direction: column;
    transform: translatex(100%);
    transition: transform 0.2s ease-in-out;
  }
  .nav.open {
    transform: translatex(0);
  }
  // .nav__container {
  //   background-color: none;
  //   top: 0;
  //   right: 0;
  //   width: 0;
  //   height: 100vh;
  //   position: absolute;
  //   overflow: hidden;
  //   z-index: 998;
  //   transition: background-color 0.4s ease;
  //   transition: width 0s 0.4s ease;
  // }

  // .nav__container.open {
  //   width: 100vw;
  //   background-color: rgba(0, 0, 0, 0.3);
  //   transition: width 0s 0s ease;
  // }
  .nav .nav__item {
    width: 100%;
    margin: 10px 0;
    padding: 10px 20px;
    border-left: 2px solid transparent;
  }
  .nav .btn--accent {
    margin: 20px 0;
    width: 100%;
  }

  .nav__item:hover {
    border-left: 2px solid #333;
    border-bottom: 2px solid transparent;
  }
  .nav__item.active {
    border-left: 2px solid #ffe100;
    border-bottom: 2px solid transparent;
    color: #fff;
  }

  .nav__item.router-link-active {
    border-bottom: 2px solid #ffe100;
    color: #fff;
  }

  // .nav__control {
  //   display: none;

  //   &:checked {
  //     & + .nav {
  //       transform: translatex(0);
  //     }
  //     & ~ .nav__container {
  //       width: 100vw;
  //       background-color: rgba(0, 0, 0, 0.6);
  //     }
  //   }
  // }
}

@media (min-width: 767px) {
  .mobile {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .desktop {
    display: none;
  }
}
</style>