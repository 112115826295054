import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router'
import App from '/src/App.vue';
import FeedPage from '/src/pages/FeedPage.vue';
import HeaderNav from '/src/components/HeaderNav.vue';
import LoaderAni from '/src/components/shared/LoaderAni.vue'
import helpers from "/src/mixins/helpers.js";
import members from "/src/mixins/members.js";
import VueGtag from "vue-gtag";
import BannerMessage from "/src/components/shared/BannerMessage.vue";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: "/", name: "feed", component: () => import('/src/pages/FeedPage.vue') },
        { path: "/account", component: () => import('/src/pages/AccountPage.vue') },
        { path: "/create-profile", component: () => import('/src/pages/CreateProfile.vue') },
        { path: "/create-event", component: () => import('/src/pages/CreateEvent.vue') },
        { path: "/artist", component: () => import('/src/pages/ProfilePage.vue') },
        { path: "/events", component: () => import('/src/pages/EventsFeed.vue') },
        { path: "/events/:slug", component: () => import('/src/pages/EventDetail.vue') },
        { path: "/contact", component: () => import('/src/pages/ContactPage.vue') },
        { path: "/auth", component: () => import('/src/pages/AuthPage.vue') },
        { path: "/test-page", component: () => import('/src/pages/TestPage.vue') },
        { path: "/:slug", component: () => import('/src/pages/ProfilePage.vue') },
        { path: "/404", component: () => import('/src/pages/ErrorPage.vue') },

    ],
    linkActiveClass: 'active'
});


const app = createApp(App);

app.use(router)
app.mixin(helpers)
app.mixin(members)
// app.component('http-requests', http);
app.component('banner-message', BannerMessage);
app.component('header-nav', HeaderNav);
app.component('feed-page', FeedPage);
app.component('loader-ani', LoaderAni);

app.use(VueGtag, {
    pageTrackerExcludedRoutes: [
        '/test-page',
        '/auth'
    ],
    config: {
        id: process.env.VUE_APP_GOOGLE_MEASUREMENT_ID, params: {
            anonymize_ip: true
        }
    }
}, router)

app.mount('#app');

// to run app in production mode and use .env.production:
// vue-cli-service build --mode production