<template>
  <div
    :class="type ? 'msg ' + type : 'msg'"
    v-html="message"
  ></div>
</template>

<script>
export default {
  props: ["message", "type"],
  data: () => {},
};
</script>

<style scoped>
.msg {
  color: dodgerblue;
  font-size: 0.8em;
  line-height: 130%;
  padding: 10px;
  border-radius: 10px;
  background: rgba(30, 143, 255, 0.1);
  border: 1px solid rgba(30, 143, 255, 0.3);
}

.sm {
  font-size: 0.8em;
  line-height: 130%;
  padding: 2px 6px;
  border-radius: 4px;
}

.xs {
  font-size: 0.6em;
  line-height: 130%;
  padding: 1px 4px;
  border-radius: 4px;
}

.yellow {
  color: goldenrod;
  background: rgba(218, 165, 32, 0.1);
  border: 1px solid rgba(218, 165, 32, 0.3);
}

.green {
  color: darkcyan;
  background: rgba(64, 224, 208, 0.13);
  border: 1px solid rgba(64, 224, 208, 0.31);
}

.grey {
  color: #666;
  background: #eee;
  border: 1px solid #ddd;
}

.dark {
  color: #aaa;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

a {
  text-decoration: underline;
}
</style>