export default {
  methods: {
    async updateMemberstack(member, fields) {
      window.MemberStack.onReady.then(function () {
        // member.updateProfile(
        //   {
        //     "event-host": true,
        //   },
        //   false
        // );
        member.updateProfile(
          fields,
          false
        );
      });

    },
  }
}