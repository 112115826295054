<template>
  <loader-ani></loader-ani>
  <section>
    <div class="header">
      <header-nav />
    </div>
    <router-view />
  </section>
</template>

<script>
import HeaderNav from "/src/components/HeaderNav.vue";
import LoaderAni from "/src/components/shared/LoaderAni.vue";

export default {
  components: { LoaderAni, HeaderNav },
  data() {
    return {
      member: {},
      apiURL: process.env.VUE_APP_API,
      visitorCountry: "",
      visitorCity: "",
      loading: false,
      isEventHost: false,
      superadmin: false,
      superadmins: ["nickatodd@gmail.com", "mizz_monika@hotmail.com"],
    };
  },
  watch: {
    $route: async function () {
      this.$root.loading = false;
    },
  },
  async mounted() {
    // CHECK FOR LOGGED IN USER
    this.member = await window.MemberStack.onReady;
    // example of how to update member
    // this.updateMemberstack(this.member, { "event-host": true });

    if (this.member && this.member.loggedIn) {
      // CHECK IF EVENT HOST
      if (this.member["event-host"]) {
        this.isEventHost = true;
      }
      // CHECK IF SUPER ADMIN
      for (let email of this.superadmins) {
        if (email === this.member.email) {
          this.superadmin = true;
          console.log("Logged in as super admin");
        }
      }
    }

    // GET COUNTRY
    this.$root.visitorCountry = "";
    let response = await fetch(
      "https://api.ipregistry.co/?key=ausrr4b5i26ay6lp"
    );
    let payload = await response.json();
    this.visitorCountry = payload.location.country.name;
    this.visitorCity = payload.location.country.name;
  },
};
</script>

<style>
@import "/src/css/webflow.css";
@import "/src/css/normalize.css";
@import "/src/css/styles.scss";
section {
  position: relative;
}
.header {
  position: absolute;
  height: 90px;
  width: 100%;
  z-index: 9999;
}
</style>