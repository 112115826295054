// export const Requests = {

//     async test() {
//         console.log("it works")
//         return "it works"
//     },
//     async get(url) {
//         const response = await fetch(url);
//         const resData = await response.json();
//         return resData;
//     },
//     async post(url, data) {
//         const response = await fetch(url, {
//             method: 'POST',
//             headers: {
//                 'Content-type': 'application/json'
//             },
//             body: JSON.stringify(data)
//         });
//         const resData = await response.json();
//         return resData;
//     }
// }

export default {
  data() {
    return {
    }
  },
  methods: {
    //     async test() {
    //         console.log("it works")
    //         return "it works"
    //     },
    async get(url) {
      const response = await fetch(url);
      const resData = await response.json();
      return resData;
    },
    async post(url, data, formData) {
      // only stringify if not sending formData
      let body = formData ? data : JSON.stringify(data)
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: body
      });
      const resData = await response.json();
      return resData;
    },
    debounce(fn, delay) {
      console.log("debouncing")
      var timeoutID = null
      return function () {
        clearTimeout(timeoutID)
        var args = arguments
        var that = this
        timeoutID = setTimeout(function () {
          fn.apply(that, args)
        }, delay)
      }
    }
  }
}